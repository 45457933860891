<template>
  <div v-if="clonedAuthorization"
       class="renewal-modal modal fade"
       id="renewal-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="renewal-modal-label"
       aria-hidden="true">
    <div class="modal-dialog modal-lg"
         role="document">
      <button class="close modal-close-button"
              type="button"
              data-dismiss="modal"
              aria-label="Close">
        <span aria-hidden="true"><i class="icon-delete"></i></span>
      </button>
      <div class="modal-content">
        <FormError v-if="error"
                   :error="error"/>
        <div class="modal-header">
          <h3 class="modal-title"
              id="renewal-modal-label">
            Demande de renouvellement effectuée le :
          </h3>
        </div>
        <div class="modal-body">
          <FormDatePicker v-model="renewalDateAsk"
                          id="renewal_date_ask"
                          name="renewal_date_ask"
                          placeholder="JJ/MM/AAAA"/>
          <h3 class="title d-inline-block mr-5">N° de demande</h3>
          <FormInput v-model="clonedAuthorization.renewal_num"
                     class="d-inline-block"
                     id="renewal_num"
                     name="renewal_num"/>
          <h3 class="title d-inline-block mr-5">Renouvellement accordé</h3>
          <FormSelect v-model="clonedAuthorization.renewal_status"
                      :selected-at-startup="clonedAuthorization.renewal_status"
                      class="d-inline-block"
                      id="renewal_status"
                      name="renewal_status"
                      :options="renewalStatusOptions"
                      value-to-select="name"
                      label-field="label"/>
          <template v-if="showRenewalDateLastStatus">
            <h3 class="title d-inline-block mr-5">Renouvelée la dernière fois le</h3>
            <FormDatePicker v-model="renewalDateLastStatus"
                            id="renewal_date_last_status"
                            name="renewal_date_last_status"
                            placeholder="JJ/MM/AAAA"/>
          </template>
        </div>
        <div class="modal-footer">
          <button @click="submit"
                  :disabled="!formFilled"
                  type="button"
                  class="btn btn-secondary">
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormError from '@/components/FormError'
import FormDatePicker from '@/components/FormDatePicker'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import { mapState, mapActions } from 'vuex'
import { dateStringToTimestamp, parseDate, clone } from '@/utils'

export default {
  components: {
    FormError,
    FormDatePicker,
    FormInput,
    FormSelect
  },
  data () {
    return {
      error: null,
      clonedAuthorization: null,
      renewalStatusOptions: [
        {
          name: 'none',
          label: 'aucun'
        },
        {
          name: 'waiting',
          label: 'en attente'
        },
        {
          name: 'accepted',
          label: 'accepté'
        },
        {
          name: 'declined',
          label: 'refusé'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      authorization: state => state.authorization.authorization
    }),
    renewalDateAsk: {
      get () {
        if (!this.clonedAuthorization.renewal_date_ask) return null
        const date = this.clonedAuthorization.renewal_date_ask.replace(/\//g, '-')
        return dateStringToTimestamp(date, '-')
      },
      set (value) {
        if (value === null) {
          this.clonedAuthorization.renewal_date_ask = value
        } else {
          this.clonedAuthorization.renewal_date_ask = parseDate(value, 'demande de renouvellement effectuée le').replace(/\//g, '-')
        }
      }
    },
    renewalDateLastStatus: {
      get () {
        if (!this.clonedAuthorization.renewal_date_last_status) return null
        const date = this.clonedAuthorization.renewal_date_last_status.replace(/\//g, '-')
        return dateStringToTimestamp(date, '-')
      },
      set (value) {
        if (value === null) {
          this.clonedAuthorization.renewal_date_last_status = value
        } else {
          this.clonedAuthorization.renewal_date_last_status = parseDate(value, 'renouvelée la dernière fois le').replace(/\//g, '-')
        }
      }
    },
    showRenewalDateLastStatus () {
      return !['waiting', 'none'].includes(this.clonedAuthorization.renewal_status)
    },
    formFilled () {
      let filled = !!this.clonedAuthorization.renewal_date_ask
      if (this.showRenewalDateLastStatus) {
        filled = filled && !!this.clonedAuthorization.renewal_date_last_status
      }
      return filled
    }
  },
  methods: {
    ...mapActions({
      updateAuthorizationRenewal: 'authorization/updateAuthorizationRenewal'
    }),
    submit () {
      if (!this.formFilled) return
      this.error = null
      this.updateAuthorizationRenewal(this.getFormData())
        .then(() => {
          this.$emit('close-modal')
        })
        .catch(error => {
          if (error.response) {
            this.error = error.response.data
          } else {
            this.error = {
              message: 'Une erreur est survenue, merci de réessayer...'
            }
          }
        })
    },
    getFormData () {
      const formData = new FormData()
      formData.append('renewal_date_ask', this.clonedAuthorization.renewal_date_ask.replace(/\//g, '-'))
      if (this.clonedAuthorization.renewal_num) {
        formData.append('renewal_num', this.clonedAuthorization.renewal_num)
      }
      formData.append('renewal_status', this.clonedAuthorization.renewal_status)
      if (this.showRenewalDateLastStatus) {
        formData.append('renewal_date_last_status', this.clonedAuthorization.renewal_date_last_status.replace(/\//g, '-'))
      }
      return formData
    }
  },
  mounted () {
    this.clonedAuthorization = clone(this.authorization)
  },
  beforeDestroy () {
    this.$emit('close-modal')
  }
}
</script>

<style scoped lang="scss">
  .modal-close-button {
    color: $gray-text;
    opacity: 1;
    text-shadow: none;
    position: absolute;
    top: 25px;
    right: 30px;
    z-index: 9999;
    font-size: 14px;
    pointer-events: all;

    &:hover,
    &:active,
    &:focus {
      cursor: pointer;
      outline: none;
    }

    @media (max-width: $breakpoint-md) {
      top: 20px;
      right: 20px;
    }
  }

  h3 {
    font-weight: normal;
  }

  .title {
    vertical-align: -5px;
  }

  .modal-content {
    padding: 90px 100px 60px 100px;
    @media (max-width: $breakpoint-md) {
      padding: 45px 50px 30px 50px;
    }
  }

  .modal-footer {
    justify-content: center;
  }
</style>
