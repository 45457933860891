<template>
  <span :class="renewalClasses">
    {{ renewalStatus }}
  </span>
</template>

<script>
export default {
  props: {
    authorization: {
      type: Object,
      required: true
    }
  },
  computed: {
    renewalStatus () {
      switch (this.authorization.status) {
        case 'ongoing input':
          return 'en finalisation'
        case 'waiting for validation':
          return 'à valider'
        case 'validated':
          return 'validée'
        case 'not valid':
          return 'non valide'
        case 'deleted':
          return 'supprimée'
        case 'to be renewed':
          return 'à renouveler'
        case 'waiting for renewal':
          return 'en consultation'
        default:
          return '???'
      }
    },
    renewalClasses () {
      return {
        badge: true,
        'badge-pill': true,
        'badge-gray': this.authorization.status === 'waiting for validation',
        'badge-blue': this.authorization.status === 'validated',
        'badge-red': ['not valid', 'deleted'].includes(this.authorization.status),
        'badge-orange': this.authorization.status === 'to be renewed',
        'badge-yellow': this.authorization.status === 'waiting for renewal',
        'badge-purple': this.authorization.status === 'ongoing input'
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .badge {
    color: $white;
    font-size: 13px;

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
    }
  }

  .badge-orange {
    background: $orange;
  }

  .badge-yellow {
    background: $yellow;
  }

  .badge-red {
    background: $red;
  }

  .badge-green {
    background: $green;
  }

  .badge-blue {
    background: $blue;
  }

  .badge-purple {
    background: $purple;
  }

  .badge-gray {
    background: $gray-text-light;
  }
</style>
