<template>
  <div v-if="authorization" class="fullscreen">


    <div class="d-flex align-items-center mb-3">
      <BackToAuthorizationsIndex/>
      <div @click="toggleShowSidenav" class="font-weight-bold ml-4 pointer">

        <div v-if="showSidenav">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             version="1.1" id="Capa_1" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 30.021 30.021"
             style="enable-background:new 0 0 30.021 30.021;" xml:space="preserve">
<g>
  <path
      d="M29.069,22.276c-0.791,0.932-1.917,1.409-3.052,1.409c-0.913,0-1.834-0.312-2.587-0.949l-8.42-7.152l-8.42,7.151   c-1.683,1.43-4.208,1.225-5.639-0.459c-1.43-1.686-1.224-4.208,0.46-5.64l11.01-9.351c1.493-1.269,3.686-1.269,5.178,0   l11.011,9.351C30.294,18.068,30.499,20.591,29.069,22.276z"/>
</g>
</svg>
      </div>
        <div v-if="!showSidenav">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             version="1.1" id="Capa_1" x="0px" y="0px" width="18px" height="18px" viewBox="0 0 30.021 30.021"
             style="enable-background:new 0 0 30.021 30.021;" xml:space="preserve">
<g>
  <path
      d="M28.611,13.385l-11.011,9.352c-0.745,0.633-1.667,0.949-2.589,0.949c-0.921,0-1.842-0.316-2.589-0.949L1.411,13.385   c-1.684-1.43-1.89-3.954-0.46-5.638c1.431-1.684,3.955-1.89,5.639-0.459l8.421,7.151l8.42-7.151   c1.686-1.43,4.209-1.224,5.639,0.459C30.5,9.431,30.294,11.955,28.611,13.385z"/>
</g>

</svg>
        </div>

      </div>
    </div>

    <div class="row">
      <div :class="showSidenav ? 'col-2 pr-0' : 'd-none'">

        <div v-if="showSidenav">
          <AuthorizationSummary :authorization="authorization"
                                :show-status="true"/>
          <div class="actions">
            <router-link v-if="isAdmin" :to="editLink"
                         class="btn btn-block btn-outline-secondary">
              Modifier les infos
            </router-link>
            <button v-if="isAdmin" @click="editSteps"
                    class="btn btn-block btn-outline-secondary">
              Éditer l'itinéraire
            </button>
            <button @click="getPdf"
                    class="btn btn-block btn-outline-secondary">
              Voir le pdf
            </button>
            <button @click="showSteps"
                    :disabled="showStepsButtonDisabled"
                    class="btn btn-block btn-outline-secondary">
              Visualiser l'itinéraire
            </button>
            <button v-if="showRenewalButton && isAdmin"
                    type="button"
                    data-toggle="modal"
                    data-target="#renewal-modal"
                    class="btn btn-block btn-outline-secondary">
              Indiquer renouvellement
            </button>
            <button v-if="showSubmitForValidationButton"
                    @click="submitForValidation"
                    class="btn btn-block btn-secondary">
              Soumettre pour validation
            </button>
            <button v-if="authorizationIsWaitingForValidation && userCanValidateAuthorization"
                    @click="goToValidation"
                    class="btn btn-block btn-secondary">
              Passer à la validation
            </button>
            <button v-if="isAdmin" @click="duplicateAndReverse"
                    class="btn btn-block btn-outline-secondary">
              Dupliquer pour retour
            </button>
            <AuthorizationRenewalModal v-if="showRenewalButton"
                                       @close-modal="closeModal"
                                       id="renewal-modal"/>
          </div>
        </div>
      </div>

      <div style="min-height: 800px;" :class="showSidenav ? 'col-10' : 'col-12'">

        <AuthorizationMap :authorization="authorization"
                          :steps="steps"
                          :center="center"/>
      </div>
    </div>
  </div>
</template>

<script>
import SplitPage from '@/components/SplitPage'
import BackToAuthorizationsIndex from '@/components/BackToAuthorizationsIndex'
import AuthorizationSummary from '@/components/AuthorizationSummary'
import AuthorizationRenewalModal from '@/components/AuthorizationRenewalModal'
import AuthorizationMap from '@/components/AuthorizationMap'
import {getAuthorizationPdf, duplicateReverseAuthorization} from '@/api/premat'

import {mapState, mapActions} from 'vuex'
import geojsonExtent from '@mapbox/geojson-extent'
import geojsonMerge from '@mapbox/geojson-merge'
import $ from 'jquery'

export default {
  name: 'AuthorizationsShowPage',
  components: {
    SplitPage,
    BackToAuthorizationsIndex,
    AuthorizationSummary,
    AuthorizationRenewalModal,
    AuthorizationMap
  },
  data() {
    return {
      showRenewalModal: true,
      showSidenav: false,
      isAdmin : true
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      authorization: state => state.authorization.authorization,
      steps: state => state.authorization.steps
    }),
    editLink() {
      return {
        name: 'authorizations.edit',
        params: {
          id: this.$route.params.id
        }
      }
    },
    showStepsButtonDisabled() {
      return this.steps === undefined || this.steps.length === 0
    },
    center() {
      if (this.steps.length) {
        return geojsonExtent.bboxify(geojsonMerge.merge(this.steps)).bbox
      } else {
        // const cityStart = [this.authorization.city_start.latitude, this.authorization.city_start.longitude]
        // const cityEnd = [this.authorization.city_end.latitude, this.authorization.city_end.longitude]
        // return [cityStart, cityEnd]
        // The authorization does not really have city_start and city_end now
        // Return GPS coordinates to see the France
        return [[51.115187, -5.344928], [40.957331, 9.975715]]
      }
    },
    showRenewalButton() {
      return ['not valid', 'validated', 'to be renewed'].includes(this.authorization.status)
    },
    authorizationIsWaitingForValidation() {
      return this.authorization.status === 'waiting for validation'
    },
    showSubmitForValidationButton() {
      return this.authorization.status === 'ongoing input' && this.steps.length >= 2
    },
    userCanValidateAuthorization() {
      return this.user && this.user.user && ['manager', 'admin'].includes(this.user.user.access)
    }
  },
  methods: {
    ...mapActions({
      getAuthorization: 'authorization/getAuthorization',
      getSteps: 'authorization/getSteps',
      resetState: 'authorization/resetState'
    }),
    getPdf() {
      getAuthorizationPdf(this.authorization)
    },
    toggleShowSidenav() {
      this.showSidenav = !this.showSidenav
    },
    showSteps() {
      if (this.showStepsButtonDisabled) return
      this.$router.push({
        name: 'authorizations.steps.show',
        params: {
          id: this.authorization.id,
          num_step: this.steps[0].properties.num_step
        }
      })
    },
    editSteps() {
      this.$router.push({
        name: 'authorizations.steps.index',
        params: {
          id: this.authorization.id
        }
      })
    },
    submitForValidation() {
      this.goToValidation()
    },
    goToValidation() {
      this.$router.push({
        name: 'authorizations.validate',
        params: {
          id: this.$route.params.id
        }
      })
    },
    closeModal() {
      $('#renewal-modal').modal('hide')
    },
    duplicateAndReverse() {
      duplicateReverseAuthorization(this.authorization.id).then(response => {
        console.log('response.data = ', response.data)
        console.log('response.data.authorization = ', response.data.authorization)
        console.log('response.data.authorization.id = ', response.data.authorization.id)
        alert('Authorisation dupliquée : id = ' + response.data.authorization.id)
      })
          .catch(error => {
            alert('ERREUR :\n' + error + '\n' + JSON.stringify(error.response.data))
          })
    }
  },
  beforeMount() {
    let user = Object.values(JSON.parse(localStorage.getItem('user')))[0].login
    if(user === 'conducteur') {
    this.isAdmin = false
    }
  },
  mounted() {
    this
        .getAuthorization(this.$route.params.id)
        .then(() => this.getSteps(this.$route.params.id))
        .catch(() => this.$router.push({name: '404'}))
  },
  beforeDestroy() {
    this.closeModal()
    $('#renewal-modal').modal('dispose')
    this.resetState()
  }
}
</script>

<style scoped lang="scss">
.fullscreen {
  height : 100vh;
}
.actions {
  // margin-top: 80px;
  margin-top: 20px;
  padding: 1px;
}
.pointer {
  cursor: pointer;
}
</style>
