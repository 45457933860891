<template>
  <div class="authorization-summary px-3">
    <div class="block">
      <div class="block-title">Status<br>
        <AuthorizationStatusBadge v-if="showStatus"
                                  :authorization="authorization"
                                  />
                                  <!-- class="mb-4 mb-lg-5"/> -->
      </div>
    </div>
    <!-- <div class="d-lg-flex">
      <div class="block flex-lg-fill">
        <div class="block-title">Dimensions (Lxlxh) (en m)</div>
        <div class="block-content">{{ dimensions }}</div>
      </div>
      <div class="block flex-lg-fill">
        <div class="block-title">Poids</div>
        <div class="block-content">{{ weight }}</div>
      </div>
      <div class="block flex-lg-fill">
        <div class="block-title">Tonnage</div>
        <div class="block-content">{{ authorization.tonnage }}</div>
      </div>
    </div> -->
    <div class="block">
      <div class="block-title">L. x l. x h. (en m)</div>
      <div class="block-content">{{ dimensions }}</div>
    </div>
    <div class="block">
      <div class="block-title">Tonnage</div>
      <div class="block-content">{{ authorization.tonnage }}</div>
    </div>
    <!-- <div class="block">
      <div class="block-title">Numéro de l'autorisation</div>
      <div class="block-content">{{ authorization.num_authorization_on_pdf }}</div>
    </div> -->
    <div class="block">
      <div class="block-title">Date début validité</div>
      <div class="block-content">{{ dateStart }}</div>
    </div>
    <div class="block">
      <div class="block-title">Date fin validité</div>
      <div class="block-content">{{ dateEnd }}</div>
    </div>
    <div class="block">
      <div class="block-title">Date demande renouvellement</div>
      <div class="block-content">{{ expectedRenewalDate }}</div>
    </div>
    <div class="block">
      <div class="block-title">Numéro de demande</div>
      <div class="block-content">{{ renewalNumber }}</div>
    </div>
    <div class="block">
      <div class="block-title">Distance</div>
      <div class="block-content">{{ nbKmWay0 }}</div>
    </div>
    <div class="block">
      <div class="block-title">Date création</div>
      <div class="block-content">{{ creationDate }}</div>
    </div>
  </div>
</template>

<script>
import AuthorizationStatusBadge from '@/components/AuthorizationStatusBadge'
import { formatDate, formatDimensions, formatWeight } from '@/utils'

export default {
  components: {
    AuthorizationStatusBadge
  },
  props: {
    authorization: {
      type: Object,
      required: true
    },
    showStatus: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    dimensions () {
      return formatDimensions(this.authorization)
    },
    weight () {
      return formatWeight(this.authorization)
    },
    dateStart () {
      return formatDate(this.authorization.date_start)
    },
    dateEnd () {
      return formatDate(this.authorization.date_end)
    },
    expectedRenewalDate () {
      return formatDate(this.authorization.renewal_date_ask)
    },
    renewalNumber () {
      return this.authorization.renewal_num
    },
    nbKmWay0 () {
      return Math.round(this.authorization.nb_km_way_0) + ' km'
    },
    creationDate () {
      return this.authorization.creation_date
    }
  }
}
</script>

<style scoped lang="scss">
  .authorization-summary {
    padding: 1px;
  }

  .block {
    // margin-bottom: 28px;
    margin-bottom: 5px;
    @media (max-width: $breakpoint-md) {
      font-size: 13px;
      // margin-bottom: 15px;
    }
  }

  .block-title {
    font-size: 18px;
    font-weight: $font-weight-medium;
    // margin-bottom: 5px;
    margin-bottom: 0px;
    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
</style>
